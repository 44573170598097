<template>
  <div class="request-messages">
      <warning-icon color="#EDEDED" width="34" height="34" />
      <p>Сообщений пока нет</p>
  </div>
</template>

<script>
import WarningIcon from '../components/icons/WarningIcon.vue'
export default {
  components: { WarningIcon },
 name: 'RequestMessages'
}
</script>

<style lang="scss">
 .request-messages {
     display: flex;
     flex-direction: column;
     align-items: center;
    padding: 40px; 

    p {
        margin-top: 15px;
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 19px;
        color: #6F6F6F;
    }
 }
</style>