<template>
<div class="tender-positions container">
  <div class="columns mb-1">
    <div class="column pt-6 pb-5">
      <tnd-pre-price value="1 855 000 232 руб"></tnd-pre-price>
    </div>
    <div class="column is-flex is-justify-content-end is-align-items-end">
      <a class="tender-positions__link red" href="">Выгрузить в Excel <excel-icon class="icon" color="#A01B5B"/></a>
      <a class="tender-positions__link green" href="">Загрузить из Excel<excel-icon class="icon"/></a>
    </div>
  </div>
 <div class="tender-positions__table-container">
      <tnd-table  :data="tableData" :columns="tableColumns" v-slot="{props, column}">
        <div v-if="column.type === 'description'">
          <p class="row-title">{{props.row[column.field].title}}</p>
          <p class="row-description">{{props.row[column.field].description}}</p>
        </div>
        <div v-if="column.type === 'editable'">
          <tnd-input  v-model="props.row[column.field]"/>
        </div>
        <div v-if="column.type === 'checkable'">
          <input type="checkbox" v-model="props.row[column.field]">
        </div>
        <div v-if="column.type === 'computable'">
            {{props.row[column.field](USDPrice)}}
        </div>
      </tnd-table>
  </div>
</div>
 
</template>

<script>
import TndTable from '../components/common/TndTable.vue'
import ExcelIcon from '../components/icons/ExcelIcon.vue'
import TndInput from '../components/common/TndInput.vue'
import TndPrePrice from '../components/common/TndPrePrice.vue'
export default {
  components: { TndTable, ExcelIcon, TndInput, TndPrePrice },
  data: () => ({
    tableValues: {},
    materialPrice: null
  }),
  computed: {
    tableColumns() {
      return this.$store.getters['tenderRequest/tenderPosTableColumns']
    },
    tableData() {
      return this.$store.getters['tenderRequest/tenderPosTableData']
    },
    USDPrice() {
      return this.$store.getters['tenderRequest/USDPrice']
    }
  }, 
  created() {
    this.$store.dispatch('tenderRequest/getUSDPrice')
  }
}
</script>

<style lang="scss" scoped>
  .tender-positions {

    &__link {
      display: flex;
      justify-content: start;
      align-items: center;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;

      &.red {
        color: #A01B5B;
      }

      &.green {
        color: #27AE60;
      }

      &+& {
        margin-left: 25px;
      }

      .icon {
        margin-left: 10px;
      }
    }

    &__table-container {
      overflow-x: auto;
    }
  }
</style>