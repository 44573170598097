<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.99968 1.66732C5.3973 1.66732 1.66634 5.39828 1.66634 10.0006C1.66634 14.603 5.3973 18.334 9.99967 18.334C14.602 18.334 18.333 14.603 18.333 10.0007C18.333 5.39828 14.602 1.66732 9.99968 1.66732Z" :stroke="color" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M10 6.66667L10 10" :stroke="color" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M10 13.334L9.99167 13.334" :stroke="color" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'ExclamationInCircleIcon',
  props: {
    color: {
      type: String,
      default: '#A01B5B'
    }
  }
}
</script>

<style>

</style>