<template>
  <div class="request-files">
    <div class="request-files__notice-container" v-if="notices.length">
      <tnd-notice v-for="(notice, index) of notices" :key="index" :text="notice.text"/>
    </div>
  

    <div class="request-files__section">
      <div class="section-info">
        <div class="section-title">
          <span>
            Коммерческое предложение
          </span>
          <exclamation-in-circle-icon/>
          </div>
        <p class="section-description">Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.</p>
      </div>
      <div class="columns is-gapless">
        <div class="column is-half">
          <tnd-upload-file/>
        </div>
      </div>
    </div>
    <div class="request-files__section">
      <div class="section-info">
        <div class="section-title">
          <span>
            Протокол переговоров
          </span>
          </div>
        <p class="section-description">Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.</p>
      </div>
      <div class="columns is-gapless">
        <div class="column is-half">
          <tnd-upload-file/>
        </div>
      </div>
    </div>
    <div class="request-files__section">
      <div class="section-info">
        <div class="section-title">
          <span>
            Другие файлы или документы
          </span>
          </div>
        <p class="section-description">Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.</p>
      </div>
      <div class="columns is-gapless">
        <div class="column is-half">
          <tnd-upload-file :files="otherDocs" multiple/>
        </div>
      </div>
    </div>
  
  </div>
</template>

<script>
import TndUploadFile from '../components/common/TndUploadFile.vue'
import TndNotice from '../components/common/TndNotice.vue'
import ExclamationInCircleIcon from '../components/icons/ExclamationInCircleIcon.vue'
export default {
  components: { TndUploadFile, TndNotice, ExclamationInCircleIcon },
  name: 'RequestFiles',
  data: () => ({
    notices: [
      {
        text: 'Обьязательные документы не загружены. Загрузите файлы.'
      }
    ],
    otherDocs: [{
      name: 'Заявка на поставку ТМЦ.docx',
      date: '12 Декабря 2021, 09:37',
      size: '263'
    }]
  })
}
</script>

<style lang="scss" scoped>
.request-files {
  &__notice-container {
    margin-bottom: 33px;
  }

  &__section {
    padding: 27px 0 37px;
    & + & {
      border-top: 1px solid #E4E4E4;
    }
    .section-info {
      padding-bottom: 30px;

      .section-title {
        display: flex;
        align-items: center;
        font-style: normal;
        font-weight: 400;
        font-size: 22px;
        line-height: 26px;
        color: #323232;
        margin-bottom: 9px;

        span {
          margin-right: 14px;
        }
    }

    .section-description {
      font-style: normal;
      font-weight: 350;
      font-size: 15px;
      line-height: 17px;
      color: #6F6F6F;
    }
    }
    
  }
}

</style>