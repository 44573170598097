<template>
  <div class="tnd-file-upload">
    <div class="tnd-file-upload__uploaded-files">
      <tnd-file 
        v-for="(file, index) of filesList" 
        :file="file" 
        action="delete" 
        @action="deleteFile"
        :key="index"/>
    </div>
    <label 
      class="tnd-file-upload__upload-container" 
      @dragover.prevent
      @dragleave="dragleave" 
      @drop.prevent="uploadFile"
      v-if="multiple || (!multiple && !filesList.length)">
      <input 
      type="file" 
      ref="file" 
      @change="uploadFile"
      :multiple="multiple"/>
      <div class="upload-icon">
        <file-icon/>
      </div>
      <div class="upload-text">
        Выберите файл или перетащите в эту область
      </div>
    </label>
  </div>
</template>

<script>
import FileIcon from '../icons/FileIcon.vue';
import TrashIcon from '../icons/TrashIcon.vue';
import TndFile from './TndFile.vue';
export default {
  components: {
    FileIcon,
    TrashIcon,
    TndFile
},
  name: 'TndUploadFile',
  props: {
    multiple: Boolean,
    files: {
      type: [Array, Object],
      default: () => []
    }
  },
  data: () => ({
    list: [],
    kilobyte: 1024,
  }),
  computed: {
    filesList: {
      get() {
        return this.list.length ? this.list : this.files
      },
      set(newVal) {
        this.list = newVal
      }
    }
  },
  methods: {
    dragover() {},
    dragleave() {},
    uploadFile(e) {
      const files = e.target.files || e.dataTransfer.files
      const filesList = this.multiple ? Object.values(files) : [files[0]]
      this.addToList(filesList)
    },
    addToList(files) {
      if (!this.multiple && this.filesList.length) return false
      console.log(typeof files, files)
      this.filesList.push(...files.map(file => ({
        name: file.name, 
        size: Math.round(file.size/this.kilobyte),
        date: this.$moment(new Date()).format('DD MMMM YYYY, HH:mm')
      })))
    },
    deleteFile(file) {
      const index = this.filesList.findIndex(elem => elem.name === file.name && elem.size === file.size && elem.date === file.date)
      this.filesList.splice(index, 1)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/variables';
.tnd-file-upload {

  &__upload-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 36px;
    background: #FDFBFC;
    border: 1px dashed #C9C9C9;
    box-sizing: border-box;
    border-radius: 10px;

    input[type=file] {
      display: none;
    }

    .upload-icon {
      width: 16px;
      height: 20px;
      margin-bottom: 12px;
    }

    .upload-text {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #6F6F6F;
    }
  }
}
</style>