<template>
  <div class="send-request__notice-container" v-if="notices.length">
    <tnd-notice v-for="(notice, index) of notices" :key="index" :text="notice.text"/>
</div>
</template>

<script>
import TndNotice from '../components/common/TndNotice.vue'
export default {
    components: {
        TndNotice
    },
    name: 'SendRequest',
    data: () => ({
        notices: [
            {
                text: 'Статус Вашей заявки: ЧЕРНОВИК. Заявки с этим статусом не могут быть одобрены тендерной комиссией. Вам необходимо заполнить все поля и нажать на кнопку «Подать заявку»'
            },
            {
                text: 'Ошибка. Необходимо исправить: Загрузите необходимые документы.'
            }
        ]
    })
}
</script>

<style lang="scss">
</style>