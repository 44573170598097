<template>
  <div class="preview">
      <tnd-notice 
      text="Проверьте правильность заполненых данных, сформируйте и распечатайте коммерческое предложение." 
      />

      <div class="preview__body">
        <tnd-table-list :data="tenderPreview" />
      </div>
      <div class="columns is-gapless">
        <div class="column">
          <button class="button is-normal main-button purple"><printer-icon class="icon"/><span>РАСПЕЧАТАТЬ КОММЕРЧЕСКОЕ ПРЕДЛОЖЕНИЕ</span></button>
        </div>
      </div>
  </div>
</template>

<script>
import TndNotice from '../components/common/TndNotice.vue'
import PrinterIcon from '../components/icons/PrinterIcon.vue'
import TndStatus from '../components/common/TndStatus.vue'
import TndTableList from '../components/common/TndTableList.vue'
export default {
  components: { TndNotice, PrinterIcon, TndStatus, TndTableList },
  computed: {
    tenderPreview() {
      console.log(this.$store)
      return this.$store.getters['tenderRequest/tenderPreview']
    }
  }
}
</script>

<style lang="scss">
.preview {
  &__body {
    margin-bottom: 35px;

  }
  
  .button {
    white-space: normal;
    span {
      padding-left: 11px;
    }
  }
}
</style>