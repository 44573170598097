<template>
  <section class="tender-request">
    <header-vue />
    <bread-crumbs :breadcrumbItems="breadcrumbItems" />

    <div class="container text-center-mobile-only pt-6 mb-6 pr-6-tablet">
      <div
        class="
          is-flex is-align-items-center
          column
          is-flex-wrap-wrap is-justify-content-center
        "
      >
        <div class="back-arrow pr-2 mr-5">
          <router-link to="/tenders" class="link"
            ><back-arrow-icon
          /></router-link>
        </div>
        <h2
          class="
            main-title
            is-size-3-5-mobile
            has-text-left has-text-centered-mobile
            is-flex-grow-1
          "
        >
          Принять участие в тендере
        </h2>
        <div class="has-text-right-mobile has-text-left-tablet">
          <tnd-status request-status="draft" text="черновик" />
        </div>
      </div>
    </div>
    <div class="container mb-4 text-center-mobile-only">
      <div class="p-5 p-d-2 shadow-d br-2">
        <b-tabs ref="tabs" v-model="activeTab">
          <b-tab-item value="requestForm" label="1. ФОРМА ЗАЯВКИ">
            <tender-request-form @next="nextStep('tenderPos')" />
          </b-tab-item>

          <b-tab-item
            value="tenderPos"
            label="2. ПОЗИЦИИ ТЕНДЕРА"
            :disabled="tenderPos"
          >
            <tender-positions />
            <div class="is-flex is-justify-content-center mt-6">
              <button
                class="button is-normal main-button purple"
                @click="nextStep('preview')"
              >
                СОХРАНИТЬ
              </button>
            </div>
          </b-tab-item>

          <b-tab-item
            value="preview"
            label="3. ПРЕДВАРИТЕЛЬНЫЙ ПРОСМОТР"
            :disabled="preview"
          >
            <preview />
            <div class="is-flex is-justify-content-center mt-6">
              <button
                class="button is-normal main-button purple"
                @click="nextStep('requestFiles')"
              >
                ПРОДОЛЖИТЬ
              </button>
            </div>
          </b-tab-item>

          <b-tab-item
            value="requestFiles"
            label="4. ФАЙЛЫ ЗАЯВКИ"
            :disabled="requestFiles"
          >
            <request-files />
            <div class="is-flex is-justify-content-center mt-6">
              <button
                class="button is-normal main-button purple"
                @click="nextStep('sendRequest')"
              >
                СОХРАНИТЬ
              </button>
            </div>
          </b-tab-item>

          <b-tab-item
            value="sendRequest"
            label="5. ПОДАЧА ЗАЯВКИ"
            :disabled="sendRequest"
          >
            <send-request />
            <div class="is-flex is-justify-content-center mt-6">
              <button
                class="button is-normal main-button purple"
                @click="nextStep('messages')"
              >
                <hammer-icon color="#FFFFFF" style="margin-right: 10px" />
                ПОДАТЬ ЗАЯВКУ
              </button>
            </div>
          </b-tab-item>

          <b-tab-item value="messages" label="СООБЩЕНИЯ" :disabled="messages">
            <request-messages />
            <div class="is-flex is-justify-content-center mt-6">
              <button class="button is-normal main-button purple">
                НАЧАТЬ НОВУЮ ТЕМУ
              </button>
            </div>
          </b-tab-item>
        </b-tabs>
      </div>
    </div>
    <footer-vue />
  </section>
</template>

<script>
import HeaderVue from "@/components/common/Header.vue";
import FooterVue from "@/components/common/Footer.vue";
import BreadCrumbs from "@/components/common/BreadCrumbs.vue";
import TenderRequestForm from "../tenderRequest/TenderRequestForm.vue";
import BackArrowIcon from "../components/icons/BackArrowIcon.vue";
import TndStatus from "../components/common/TndStatus.vue";
import TenderPositions from "../tenderRequest/TenderPositions.vue";
import Preview from "../tenderRequest/Preview.vue";
import RequestFiles from "../tenderRequest/RequestFiles.vue";
import SendRequest from "../tenderRequest/SendRequest.vue";
import HammerIcon from "../components/icons/HammerIcon.vue";
import RequestMessages from "../tenderRequest/RequestMessages.vue";
export default {
  components: {
    HeaderVue,
    FooterVue,
    BreadCrumbs,
    TenderRequestForm,
    BackArrowIcon,
    TndStatus,
    TenderPositions,
    Preview,
    RequestFiles,
    SendRequest,
    HammerIcon,
    RequestMessages,
  },
  name: "TenderRequest",
  data: () => ({
    breadcrumbItems: [
      {
        title: "Тендеры",
        link: "/tenders",
      },
      {
        title: "request name",
        link: null,
      },
    ],
    requestForm: false,
    tenderPos: true,
    preview: true,
    requestFiles: true,
    sendRequest: true,
    messages: true,
    activeTab: 0,
  }),
  methods: {
    nextStep(tabName) {
      this.$data[tabName] = false;
      this.activeTab = tabName;
    },
  },
};
</script>

<style lang="scss" scoped>
.tender-request {
  .back-arrow {
    .link {
      display: flex;
      align-items: center;
    }
  }
}
</style>